<template>
    <t-modal v-model="showCard" v-if="availabilities" variant="wide">
        <div class="md:p-20 p-8">
            <div class="max-w-xl">
                <h1 class="text-purple h1 mb-5 pr-5">{{ product.name }}</h1>
            </div>

            <div class="w-full overflow-x-auto scrollbar scrollbar-thumb-blue-400 scrollbar-track-gray-200 scrollbar-thin pb-2" v-if="tableData">
                Table
                <dynamic-table :items="tableData">
                    <dynamic-table-column identifier="dateLabel">
                        {{ $t('common_week') }}
                    </dynamic-table-column>
                    <dynamic-table-column
                        v-for="variableValue in variableValues"
                        :key="variableValue.id"
                        :identifier="getSlotName(variableValue)">
                        {{ variableValue.name }}
                    </dynamic-table-column>

                    <template
                        v-for="(variableValue, index) in variableValues"
                        v-slot:[getSlotName(variableValue)]="item"
                    >
                        <text-button
                            v-if="item.values[index].campaignItem == null"
                            :key="getSlotName(variableValue)"
                            class="text-green-400"
                            @click.prevent="selectAvailable(item.startDate, item.endDate, variableValue)"
                        >
                            {{ $t('component_ProductAvailabilitiesCard_available') }}
                        </text-button>
                        <text-button
                            v-else-if="isRetailer"
                            :key="getSlotName(variableValue)"
                            class="text-red-400"
                            :to="{name: 'campaignDetail', params: {campaignId: item.values[index].campaign.id}}"
                            target="_blank"
                        >
                            {{ item.values[index].campaign.company.name }}<br>
                            {{ item.values[index].campaign.name }}
                        </text-button>
                        <span v-else :key="getSlotName(variableValue)">
                            {{ $t('component_ProductAvailabilitiesCard_not_available') }}
                        </span>
                    </template>
                </dynamic-table>
            </div>
        </div>
        <template v-slot:button>
            <svg-icon name="ico-close" class="md:w-14 md:h-14 w-8 h-8"></svg-icon>
        </template>
    </t-modal>
</template>
<script>
import SvgIcon from "@/components/SvgIcon";
import TextButton from "@/components/base/buttons/TextButton.vue";
import DynamicTableColumn from "@/components/universal/dynamic-table/DynamicTableColumn.vue";
import DynamicTable from "@/components/universal/dynamic-table/DynamicTable.vue";
import {mapGetters} from "vuex";

export default {
    name: "ProductAvailabilitiesCard",
    components: {DynamicTable, DynamicTableColumn, TextButton, SvgIcon},
    data() {
        return {
            availabilities: null,
            loadAvailabilities: false
        };
    },
    props: {
        showAvailabilities: {
            default: false
        },
        product: {
            default: null
        },
        emitOnSelect: {
            default: false,
            type: Boolean
        }
    },
    computed: {
        ...mapGetters('auth', ['isRetailer']),
        showCard: {
            get() {
                return !!this.availabilities && this.showAvailabilities;
            },
            set(value) {
                if (!value) {
                    this.$emit("close");
                }
            },
        },
        variableValues() {
            if (typeof this.availabilities === 'object' || Array.isArray(this.availabilities))
            {
                if (this.availabilities.product.variables.length === 0) {
                    return [{id: null, name: ''}];
                }
                return this.availabilities.product.variables[0].values;
            }
            return [];
        },
        weeks() {
            if (!this.availabilities) {
                return [];
            }
            return this.availabilities.weeks;
        },
        tableData() {
            return this.weeks;
        }
    },
    methods: {
        async getProductAvailabilities() {
            if (this.product) {
                this.loadAvailabilities = true;
                this.availabilities = await this.$store.dispatch(
                    "products/getProductAvailabilities",
                    {
                        productId: this.product.id,
                    }
                );
            }
        },
        getSlotName(variableValue) {
            return variableValue.id ? ''+ variableValue.id : 'variableValue';
        },
        async selectAvailable(startDate, endDate, variableValue) {
            startDate = new Date(startDate)
            endDate = new Date(endDate)
            if(this.emitOnSelect) {
                this.$emit('prefill', {
                    productId: this.product.id,
                    startDate: startDate,
                    endDate: endDate,
                    variableValue: variableValue.id
                });
                this.showCard = false;
                return;
            }
            await this.$store.dispatch('products/setPrefill', {
                productId: this.product.id,
                startDate: startDate,
                endDate: endDate,
                variableValue: variableValue.id
            })
            this.showCard = false;
            if(this.$route.name === 'productDetail' && this.$route.params.selectedProductId == this.product.id) {
               return;
            }
            if(this.$route.name.startsWith('campaignDetail')) {
                return;
            }
            await this.$router.push({name: 'productDetail', params: {selectedProductId: this.product.id}})
        }
    },
    created() {
        this.availabilities = [];
    },
    beforeUpdate() {
        if (this.showAvailabilities && !this.loadAvailabilities) {
            this.getProductAvailabilities();
        }
    }
};
</script>
