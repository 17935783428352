<template>
    <div class="w-full overflow-x-auto scrollbar scrollbar-thumb-blue-400 scrollbar-track-gray-200 scrollbar-thin pb-2" :class="classes">

        <table class="min-w-fit w-full">
            <thead>
                <tr class="px-4 pb-2">
                    <slot name="default"></slot>
                </tr>
            </thead>
            <draggable
                ref="dragContainer"
                :list="sortData"
                :disabled="!sortable"
                tag="tbody"
                @change="sort"
                v-bind="sortableConfig"
            >
                <template v-for="(row, index) in rows">
                    <tr
                        :key="'primary-'+index"
                        class="group"
                        :class="{'cursor-pointer': clickable}"
                        @click="clickable && click(row, $event)"
                    >
                        <template
                            v-for="columnName in columns"
                        >
                            <td
                                class="border-b-2 border-gray-50 my-1 px-4 py-3 bg-white first:rounded-l-xl last:rounded-r-xl group-hover:bg-blue-50 transition duration-100"
                                :key="columnName"
                                v-if="columnName !== 'performance'"
                            >
                                <slot :name="columnName" v-bind="row">
                                    {{ baseFormat(row[columnName]) }}
                                </slot>
                            </td>
                        </template>
                    </tr>
                    <template
                        v-if="row['displayPerformance']"
                    >
                        <tr
                            :key="'secondary-'+index"
                        >
                            <td>&nbsp;</td>
                            <td
                                :colspan="columns.length-1"
                                class="border-b-2 border-gray-50 my-1 px-4 py-3 bg-white first:rounded-l-xl last:rounded-r-xl group-hover:bg-blue-50 transition duration-100"
                            >
                                <slot :name="'performance'" v-bind="row">
                                    {{ baseFormat(row['performance']) }}
                                </slot>
                            </td>
                        </tr>
                        <tr
                            :key="'spacer-'+index"
                        >
                            <td class="text-xs">&nbsp;</td>
                        </tr>
                    </template>
                </template>
                <tr v-if="loading">
                    <td class="p-4" :colspan="columns.length">
                        <slot name="loading">
                            <loading-box :value="loading" theme="light">
                                <div class="h-28 w-full"></div>
                            </loading-box>
                        </slot>
                    </td>
                </tr>
                <tr v-if="!loading && rows.length === 0">
                    <td class="p-4" :colspan="columns.length">
                        <slot name="itemsNotFound">
                            <div class="flex justify-center m-2">
                                {{ itemsNotFoundMessage || $t('common_items_not_found') }}
                            </div>
                        </slot>
                    </td>
                </tr>
            </draggable>
        </table>
    </div>
</template>

<style>
.sortable-chosen td {
    opacity: 0.8;
    background: #DBCCFC;
}
</style>

<script>
import Draggable from "@/components/draggable/draggable";
import LoadingBox from "@/components/LoadingBox.vue";

export default {
    name: "DynamicTableMetrics",
    components: {
        LoadingBox,
        Draggable
    },
    props: {
        items: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        itemsNotFoundMessage: {
            type: String,
            default: undefined
        },
        sortable: {
            type: Boolean,
            default: false
        },
        sortableConfig: {
            type: Object,
            default: () => ({ghostClass: 'dynamicTable_ghost'})
        },
        clickable: {
            type: Boolean,
            default: false
        },
        classes: {
            type: String,
            default: undefined
        }
    },
    data() {
        return {
            sortData: []
        };
    },
    computed: {
        tableSortData() {
            return this.items.map((row) => row.id);
        },
        columns() {
            return this.columnComponents.map((item) => item.componentOptions.propsData.identifier);
        },
        rows: {
            get() {
                if(this.loading) {
                    return [];
                }
                return this.items;
            },
            set(value) {
                this.$emit('update:items', value);
            },
        },
        columnComponents() {
            return this.$slots.default.filter(item => !!item.componentOptions);
        },
        classesBody() {
            return this.$slots.default.filter(item => !!item.componentOptions).map(item => item.componentOptions.propsData.classesBody);
        },
    },
    methods: {
        sort() {
            this.$emit('sort', this.sortData);
        },
        baseFormat(value) {
            if(value === null || value === undefined) {
                return '';
            }
            return value;
        },
        click(row, event) {
            this.$emit('row-click', row, event);
        }
    },
    watch: {
        items: {
            handler() {
                this.sortData = this.items;
            },
            deep: true
        },
    },
    created() {
        this.sortData = this.items;
    }
};
</script>
