export default {
    TopInCategory: 'topInCategory',

    TopInSearch: 'topInSearch',

    TopInAllSecondCategory: 'topInAllSecondCategory',

    MatchingProvidedProducts: 'matchingProvidedProducts',

    Other: 'other'
}
