<template>
    <div class="w-full bg-gray-200 relative z-100">
        <header class="max-w-screen-2xl md:px-8 mx-auto relative lg:pt-3 min-[1320px]:pt-0"
                :class="{'rounded-b-2xl' : !menuOpen}"
        >
            <div class="flex items-center w-full justify-between py-3 lg:py-0 lg:flex-wrap min-[1320px]:flex-nowrap">
                <base-button :to="logoTo">
                    <svg-icon
                        name="logo-cruxo"
                        width="232"
                        class="-ml-6"
                    ></svg-icon>
                </base-button>
                <nav class="
                    flex-wrap
                    text-center
					absolute
                    flex-col
                    items-center
                    top-full
                    right-0
                    shadow-xl
                    border-b
                    z-40 transition
                    bg-gray-200
                    rounded-b-2xl
                    pb-6


                    lg:w-full
                    lg:contents
                    lg:visible
                    lg:opacity-100
                    lg:translate-y-0
                    lg:flex-row
                    lg:border-b-0
                    lg:relative
                    lg:shadow-none
                    lg:bg-transparent
					lg:top-0
                    lg:pb-0
                    "

                     :class="!menuOpen? 'invisible opacity-0 -translate-y-full' : 'visible opacity-100 translate-y-0'"
                >
                    <div class="lg:w-full lg:order-3 lg:pt-6 relative z-40 min-[1320px]:-order-none min-[1320px]:w-auto">
                        <main-header-item
                            v-if="isVendor || isAgency"
                            :to="{ name: 'productList' }"
                            name="products"
                        >
                            {{ $t('common_products') }}
                        </main-header-item>
                        <main-header-item
                            :to="{ name: 'campaignList' }"
                            name="campaigns"
                        >
                            {{ !isRetailer ? $t('common_myCampaigns') : $t('common_campaignsManagement') }}
                        </main-header-item>
                        <main-header-item
                            :to="{ name: 'CampaignItemsListView' }"
                            name="campaignItems"
                        >
                            {{ $t('common_campaign_items') }}
                        </main-header-item>
                        <main-header-item
                            v-if="allowDynamicLandingPages"
                            :to="{ name: 'landingPagesList' }"
                            name="landingPages"
                        >
                            {{  $t('common_landingPages') }}
                        </main-header-item>
                        <main-header-item
                            v-if="displayAvailabilitiesMenuItem"
                            :to="{ name: 'availabilities' }"
                            name="availabilities"
                        >
                            {{ $t('common_availability') }}
                        </main-header-item>
                        <main-header-item-dropdown
                            v-if="isRetailer"
                            name="administration"
                            class="z-50"
                        >
                            <template>
                                {{ $t('common_settings') }}
                            </template>
                            <template v-slot:dropdown>
                                <main-header-sub-item
                                    v-if="isAdministrator"
                                    :to="{ name: 'administrationProductCategorySettings' }"
                                >
                                    {{ $t('common_product_categories_settings') }}
                                </main-header-sub-item>
                                <main-header-sub-item
                                    v-if="isAdministrator"
                                    :to="{ name: 'administrationProductSettings' }"
                                >
                                    {{ $t('common_products_settings') }}
                                </main-header-sub-item>
                                <main-header-sub-item
                                    :to="{ name: 'userList' }"
                                >
                                    {{ $t('common_users_settings') }}
                                </main-header-sub-item>
                                <main-header-sub-item
                                    v-if="isAdministrator"
                                    :to="{ name: 'administrationLandingPageTypeSettings' }"
                                >
                                    {{ $t('common_landing_page_type') }}
                                </main-header-sub-item>
                                <main-header-sub-item
                                    v-if="isAdministrator"
                                    :to="{ name: 'creativeFormatList' }"
                                >
                                    {{ $t('common_creative_format_settings') }}
                                </main-header-sub-item>
                                <main-header-sub-item
                                    v-if="isAdministrator"
                                    :to="{ name: 'settingsVariables' }"
                                >
                                    {{ $t('common_settings_variables') }}
                                </main-header-sub-item>
                            </template>
                        </main-header-item-dropdown>
                        <main-header-item
                            v-if="allowDashboards"
                            :to="{ name: 'dashboards' }"
                            name="dashboards"
                        >
                            {{ $t('view_dashboards_title') }}
                        </main-header-item>
                        <main-header-item
                            :to="{ name: 'helpPage' }"
                            name="help"
                        >
                            {{ $t('common_help') }}
                        </main-header-item>
                    </div>
                    <t-dropdown class="lg:mt-0 mt-3 mr-0 ml-auto" variant="submenu" ref="submenu">
                        <template v-slot:trigger="{
							mousedownHandler,
							focusHandler,
							blurHandler,
							keydownHandler,
						}">
                            <t-button
                                class="flex items-center no-underline z-20 px-6"
                                @mousedown="mousedownHandler"
                                @focus="focusHandler"
                                @blur="blurHandler"
                                @keydown="keydownHandler"
                                variant="link"
                            >
                                <svg-icon
                                    name="ico-profile"
                                    width="40"
                                    class="border-blue-400 border rounded-full"
                                ></svg-icon>

                                <div v-if="originalTokenOfRetailer" class="rounded-full text-blue-700 bg-blue-100 w-6 h-6 p-1 absolute left-12 -bottom-1">
                                    <c-icon icon="door-open" class="w-4 h-4 "></c-icon>
                                </div>

                                <div class="pl-4 text-left">
                                    <span class="text-xl border-b border-zinc-700 mb-1 block leading-none">{{
                                            userName
                                        }}</span>
                                    <span class="text-sm">{{ companyName }}</span>
                                </div>
                            </t-button>
                        </template>
                        <div class="px-3 py-1 bg-white rounded-2xl">
                            <div class="mx-2 mt-4">
                                <t-rich-select
                                    v-model="locale"
                                    @change="updateLanguage()"
                                    :options="uiLocales"
                                    text-attribute="label"
                                    value-attribute="value"
                                    :hideSearchBox="true"
                                >
                                </t-rich-select>
                            </div>
                            <div v-if="canOverrideLocale" class="mx-2 mt-4">
                                <label class="w-full flex items-center">
                                    <t-checkbox v-model="overrideLocale"  @change="overrideLocaleChanged"/>
                                    <checkbox-icon style="display: inline-block"/>
                                    <span class="leading-tight whitespace-nowrap"> Override locale to keys</span>
                                </label>
                            </div>
                            <c-button
                                v-if="originalTokenOfRetailer"
                                color="green"
                                size="small"
                                append-icon="door-open"
                                @click="loginBackAsRetailer"
                                class="ml-auto mr-0 mt-3 mb-0"
                            >
                                {{ $t('common_login_back_as_retailer') }}
                            </c-button>
                            <c-button
                                color="green"
                                size="small"
                                @click="logout"
                                class="ml-auto mr-0 mt-3 mb-0"
                            >
                                {{ $t('common_logout') }}
                            </c-button>
                        </div>
                    </t-dropdown>
                </nav>
                <button class="p-4 lg:hidden" @click="menuOpen = !menuOpen"
                        :class="{'bg-blue-400 rounded-xl' : menuOpen}"
                >
                    <menu-icon class="w-10 h-8"
                               :class="menuOpen ? 'text-green-400' : 'text-blue-400'"
                    />
                </button>
            </div>
        </header>
    </div>
</template>
<script>
import SvgIcon from "@/components/SvgIcon";
import MenuIcon from "@/components/icons/MenuIcon";
import {mapGetters} from "vuex";
import MainHeaderItem from "@/components/header/MainHeaderItem";
import MainHeaderItemDropdown from "@/components/header/MainHeaderItemDropdown.vue";
import CheckboxIcon from "@/components/icons/CheckboxIcon.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import CIcon from "@/components/base/icons/CIcon.vue";
import CButton from "@/components/base/buttons/CButton.vue";
import { localeChanged } from 'vee-validate';
import MainHeaderSubItem from "@/components/header/MainHeaderSubItem.vue";


export default {
    name: "MainHeader",
    components: {
        MainHeaderSubItem,
        CButton, CIcon, BaseButton, CheckboxIcon, MainHeaderItemDropdown, MainHeaderItem, SvgIcon, MenuIcon},
    data: () => {
        return {
            menuOpen: false,
            uiLocales: [],
            overrideLocale: false,
        };
    },
    mounted() {
        if (sessionStorage.getItem("locale")) {
            this.$i18n.locale = sessionStorage.getItem("locale");
        } else {
            sessionStorage.setItem("locale", this.$i18n.locale);
        }
        this.storeCurrency();
    },
    computed: {
        ...mapGetters("auth", [
            "userId",
            "userName",
            "companyName",
            "userRoles",
            "originalTokenOfRetailer",
            "defaultPage",
            "isVendor",
            "isRetailer",
            "isAdministrator",
            "isAgency",
        ]),
        logoTo() {
            return {
                name: this.defaultPage
            }
        },
        locale: {
            get() {
                return this.$store.getters['auth/locale'];
            },
            async set(value) {
                await this.$store.dispatch("users/updatePreferredLocale", {
                    userId: this.userId,
                    locale: value,
                });
                await this.$store.dispatch("auth/setLocale", {
                    locale: value,
                });
                this.$i18n.locale = value;
                localeChanged();
            }
        },
        canOverrideLocale() {
            const host =  window.location.host;
            return this.isAdministrator && (host.endsWith('localhost') || host.endsWith('develop.izon.cz'));
        },
        allowDynamicLandingPages() {
            return this.$store.getters['appSettings/allowDynamicLandingPages'];
        },
        displayAvailabilitiesMenuItem() {
            return this.isRetailer && this.$store.getters['appSettings/displayAvailabilitiesMenuItem'];
        },
        allowDashboards() {
            return this.$store.getters['dashboards/isEnabled'];
        },
    },
    methods: {
        async logout() {
            await this.$store.dispatch("auth/logout");
        },
        updateLanguage() {
            sessionStorage.setItem("locale", this.$i18n.locale);
            this.$refs.submenu.doHide();
        },
        async getAvailableUILocales() {
            return await this.$store.dispatch("appSettings/getAvailableUILocales");
        },
        async getCurrency() {
            return await this.$store.dispatch('auth/getCurrency');
        },
        async storeCurrency() {
            const { currencyCode } = await this.getCurrency();

            for (const [locale] of Object.entries(this.$i18n.numberFormats)) {
                this.$i18n.setNumberFormat(locale, {
                    currency : {
                        ...this.$i18n.numberFormats[locale].currency,
                        currency: currencyCode,
                    }
                });
            }
        },
        overrideLocaleChanged() {
            this.$i18n.locale = this.overrideLocale ? 'no-such-locale' : this.$store.getters['auth/locale'];
            this.$i18n.fallbackLocale = this.overrideLocale ? 'no-such-locale' : 'cs-CZ';
            localeChanged();
            this.$refs.submenu.doHide();
        },
        async loginBackAsRetailer() {
            await this.$store.dispatch("users/loginBackAsRetailer");
            if (this.$route.name !== "userList") {
                await this.$router.push({name: 'userList'});
            } else {
                await this.$store.dispatch("auth/authorization");
            }
            this.$refs.submenu.doHide();
        },
        hide() {
            this.menuOpen = false;
            this.$refs.submenu.doHide();
        }
    },
    async created() {
        this.uiLocales = await this.getAvailableUILocales();
    },
};
</script>
