<template>
    <div>
        <div v-for="variable in variables" :key="variable.id" class="flex items-center my-5 bg-white rounded-lg px-4 py-1">
            <p>{{variable.name}}</p>
            <div class="w-1/4 ml-auto">
                <t-rich-select v-if="variable.identifier === 'default-sales-person'"
                    :placeholder="defaultSalesPersonName"
                    value-attribute="id"
                    :options="salesPersons"
                    text-attribute="name"
                    @change="$emit('change', $event, variable.id)"
                ></t-rich-select>
                <t-rich-select v-else
                    :placeholder="defaultTraffickerName"
                    value-attribute="id"
                    :options="traffickers"
                    text-attribute="name"
                    @change="$emit('change', $event, variable.id)"
                ></t-rich-select>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SalesPersonTraffickerSelect",
    props: {
        variables: {
            type: Array,
            default: () => ([]),
        },
        salesPersons: {
            type: Array,
            default: () => ([]),
        },
        traffickers: {
            type: Array,
            default: () => ([]),
        },
        defaultSystemUsersName: {
            type: [Object, null],
            default: () => null,
        },
    },
    data: () => {
        return {
            salesPersonId: null,
        };
    },
    computed: {
        defaultSalesPersonName () {
            if (this.defaultSystemUsersName) {
                return this.defaultSystemUsersName.defaultSalesPersonName;
            }

            return null;
        },
        defaultTraffickerName () {
            if (this.defaultSystemUsersName) {
                return this.defaultSystemUsersName.defaultTraffickerName;
            }

            return null;
        },
    },
};
</script>