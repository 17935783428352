<template>
    <div>


        <div class="my-6 mx-6">
            <target-tab-input
                ref="urlTabs"
                v-model="targetTabInput"
                :product="product"
                :company-restrictions="companyRestrictions"
                @error="setUrlError"
            ></target-tab-input>
        </div>

        <div class="md:flex md:justify-between bg-gray-50 md:p-8 p-4 rounded-2xl">
            <div class="sm:w-1/2 sm:pr-8 space-y-6">
                <div v-if="selectableVariableValues">
                    <p class="mb-1">
                        <span class="text-gray-600">
                            {{ $t('common_tags') }}
                        </span>
                    </p>
                    <t-rich-select
                        ref="variableValuesSelect"
                        :multiple="hasMultipleVariableValues"
                        v-model="variableValuesIdesSelectModel"
                        :options="selectableVariableValues"
                        class="!border-gray-300"
                        text-attribute="name"
                        value-attribute="id"
                    >
                    </t-rich-select>
                </div>
                <div class="flex flex-wrap">
                    <p class="w-full mb-1">
                        <span class="text-gray-600">{{ $t('component_productCard_displayTime') }}</span>
                    </p>

                    <div v-if="hasAvailabilities" class="mb-4">
                        <c-button
                            color="green"
                            size="medium"
                            @click.stop="showAvailabilities = true"
                        >
                            {{ $t('common_availability') }}
                        </c-button>
                    </div>

                    <div class="flex space-x-2 w-full">
                        <date-picker
                            class="lg:w-1/2"
                            v-model="startDate"
                            :disabled="disabledStartDatePicker"
                            :disabled-dates="disabledStartDates"
                            :placeholder="$t('component_productCard_from')"
                            required
                        />
                        <date-picker
                            class="lg:w-1/2"
                            v-model="endDate"
                            :disabled-dates="disabledEndDates"
                            :placeholder="$t('component_productCard_to')"
                            required
                        />
                    </div>
                </div>
                <div v-if="!isFlatRate" class="flex space-x-2 w-full">
                    <div class="lg:w-1/2">
                        <p class="mb-1">
                            <span class="text-gray-600">{{ inputClicksText }}</span>
                        </p>
                        <t-input
                            v-model="orderedAmount"
                            class="text-center"
                            :placeholder="unitPlaceHolder"
                            type="number"
                            @change="onOrderedAmountChanged"
                            required
                            :step="1"
                            :min="0"
                        ></t-input>
                    </div>

                    <div class="lg:w-1/2">
                        <p class="mb-1">
                            <span v-if="allowCustomPriceText" class="text-gray-600">
                                {{ $t('component_productCard_customPrice') }}
                                ({{ product.rateType }},&nbsp;{{ $t('component_productCard_customPrice_min') }}&nbsp;{{ $n(this.product.price, 'currency') }})
                            </span>
                            <span v-else class="text-gray-600">
                                {{ product.rateType }},&nbsp;{{ $n(this.product.price, 'currency') }}
                            </span>
                        </p>
                        <t-input
                            v-model="customProductPriceFromInput"
                            class="text-center"
                            :disabled="!allowCustomPrice"
                            type="text"
                            :min="defaultProductPrice"
                        ></t-input>
                    </div>
                </div>
            </div>

            <div class="sm:w-1/2">
                <p class="mb-1">
                    <span class="text-gray-600">
                        {{ $t('common_note') }}
                    </span>
                </p>
                <t-textarea v-model="note" class="border-gray-900 rounded-2xl lg:h-36 w-full"></t-textarea>
            </div>
        </div>

        <div v-if="product.nativeType === 'topInSearch'" class="bg-gray-50 md:p-8 p-4 mt-8 md:pb-3 pb-0 rounded-2xl">
            <p class="mb-1">
                <span class="text-gray-600">
                    {{ $t('common_type_search') }}
                </span>
            </p>
            <target-keywords-input
                ref="keywords"
                v-model="targetKeywords"
            >
            </target-keywords-input>
            <info-message type="error" v-if="keywordsNotFilled">{{ $t('common_empty_keywords') }}</info-message>
        </div>

        <div v-if="hasCheckAvailability" class="md:flex md:justify-between bg-gray-50 md:p-8 p-4 mt-4 rounded-2xl">
            <div class="mb-4">
                <c-button
                    size="medium"
                    color="green"
                    prepend-icon="calendar-31"
                    :disabled="!enableCheckAvailability"
                    :loading="checkingCampaignItemAvailability"
                    @click="checkCampaignItemAvailability"
                >
                    {{
                        checkingCampaignItemAvailability ? $t('common_checking_availability') : $t('common_check_availability')
                    }}
                </c-button>
            </div>
            <div class="mb-8">
                <p v-if="campaignItemAvailabilityText">{{ campaignItemAvailabilityText }}</p>
                <p v-if="campaignItemAvailabilityError" class="text-red-400">{{ campaignItemAvailabilityError }}</p>
            </div>
        </div>

        <info-message type="error" class="mt-2" v-if="errorMessage">
            {{ errorMessage }}
        </info-message>


        <!-- Availability popup -->
        <product-availabilities-card
            @click.stop
            v-if="hasAvailabilities"
            :showAvailabilities="showAvailabilities"
            @close="showAvailabilities = false"
            @prefill="prefill"
            :product="product"
            emit-on-select
        />
    </div>
</template>

<script>

import TargetKeywordsInput from "@/components/TargetKeywordsInput.vue";
import DatePicker from "@/components/DatePicker.vue";
import TargetTabInput from "@/components/TargetTabInput.vue";
import {add, differenceInDays, endOfToday, format, isAfter, isBefore, parseISO, startOfToday} from "date-fns";
import ProductAvailabilitiesCard from "@/components/ProductAvailabilitiesCard.vue";
import {debounce} from "lodash";
import {mapGetters} from "vuex";
import CButton from "@/components/base/buttons/CButton.vue";
import ProductNativeType from "@/utils/ProductNativeType";
import InfoMessage from "@/components/base/InfoMessage.vue";

export default {
    name: "AdToolEditForm",
    components: {
        InfoMessage,
        CButton,
        ProductAvailabilitiesCard,
        TargetTabInput,
        DatePicker,
        TargetKeywordsInput
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
        companyRestrictions: {
            type: Object,
            default: () => ({}),
        },
        activityData: {
            type: [Object, null],
            default: () => null,
        },
        allowCustomPrice: {
            type: Boolean,
            default: false,
        },
        allowCustomPriceText: {
            type: Boolean,
            default: false,
        },
        orderAmountLimit: {
            type: Number,
            default: 2000000000,
        },
    },
    data() {
        return {
            showAvailabilities: false,
            targetKeywords: [],
            targetURL: "",
            targetProduct: null,
            targetLandingPage: null,
            note: "",
            startDate: "",
            endDate: "",
            orderedAmount: 0,
            variableValueIdes: [],
            errorMessage: "",
            customProductPrice: 0,
            checkingCampaignItemAvailability: false,
            campaignItemAvailability: null,
            campaignItemAvailabilityError: null,
            defaultProductPrice: 0,
            productAvailabilities: null,
            urlError: false,
        };
    },
    computed: {
        ...mapGetters('auth', ['isRetailer']),
        hasAvailabilities() {
            return this.isFlatRate && this.product.checkAvails;
        },
        isFlatRate() {
            return this.product.rateType === "Flat Rate";
        },
        selectableVariableValues() {
            if (!this.product || this.product.variables.length === 0) {
                return null;
            }

            const values = this.product.variables[0].values;

            return values.filter(value => value.active)
                .sort((a, b) => a.name.localeCompare(b.name));
        },
        hasMultipleVariableValues() {
            return this.product.multipleVariableValues;
        },
        variableValuesIdesSelectModel: {
            get() {
                if (this.hasMultipleVariableValues) {
                    return this.variableValueIdes;
                }
                if (Array.isArray(this.variableValueIdes) && this.variableValueIdes.length > 0) {
                    return this.variableValueIdes[0];
                }
                return null;
            },
            set(value) {
                if (this.hasMultipleVariableValues) {
                    this.variableValueIdes = value;
                } else {
                    this.variableValueIdes = [value];
                }
            }
        },
        disabledStartDatePicker() {
            if (!this.campaignItem) { // do not disable for new item
                return false;
            }
            const itemStartDate = parseISO(this.campaignItem.startDate);
            const minStartDate = endOfToday();
            return isAfter(minStartDate, itemStartDate); // today is after start date
        },
        disabledStartDates() {
            const product = this.product;
            const productAvailabilities = this.productAvailabilities;
            const actualDate = startOfToday();
            const minStartDate = this.userMinStartDate;

            if (product && product.rateType !== "Flat Rate") {
                return [
                    function (date) {
                        return date < actualDate || date < minStartDate
                    }
                ];
            }
            return [
                function (date) {
                    const isPastDate = date.getDay() !== 1 || date < actualDate || date < minStartDate;
                    if(isPastDate) {
                        return true;
                    }

                    if(product.multipleVariableValues) {
                        // do not disable for multipleVariableValues products
                        return false;
                    }

                    const year = format(date, 'Y');
                    const month = format(date, 'M');
                    const day = format(date, 'd');
                    let campaignItem = null;
                    if (
                        productAvailabilities
                        && Object.hasOwn(productAvailabilities, year)
                        && Object.hasOwn(productAvailabilities[year], month)
                        && Object.hasOwn(productAvailabilities[year][month], day)
                    ) {
                        campaignItem = productAvailabilities[year][month][day];
                    }
                    return campaignItem != null;
                }
            ]
        },
        disabledEndDates() {
            if (!this.startDate) {
                return [];
            }
            const startDate = parseISO(this.startDate);
            const product = this.product;
            return [
                function (date) {
                    let status = startDate > date;
                    if (product && product.rateType !== "Flat Rate") {
                        return status;
                    }
                    return status || date.getDay() !== startDate.getDay() - 1;
                }
            ]
        },
        inputClicksText() {
            switch (this.product.rateType) {
                case "CPM":
                    return this.$t('component_productCard_inputClicksCPM');
                case "CPC":
                    return this.$t('component_productCard_inputClicksCPC');
                default:
                    return this.product.rateType;
            }
        },
        customProductPriceFromInput: {
            get() {
                return this.customProductPrice === null ? this.defaultProductPrice : parseFloat(this.customProductPrice);
            },
            set(value) {
                const parsedValue = this.parseLocaleNumber(value);

                if(parsedValue < parseFloat(this.defaultProductPrice) || isNaN(parsedValue)) {
                    value = this.defaultProductPrice;
                }
                this.customProductPrice = parsedValue;
            },
        },
        hasCheckAvailability() { // true if product has chack availabilities
            return this.product.rateType !== "Flat Rate" && this.product.checkAvails;
        },
        enableCheckAvailability() {
            return !this.checkingCampaignItemAvailability
                && this.startDate !== ""
                && this.endDate !== ""
                && (!this.selectableVariableValues || this.variableValueIdes.length > 0);
        },
        campaignItemAvailabilityText() {
            if( !this.product || !this.campaignItemAvailability ) {
                return null;
            }
            let availableParams = {
                availableCount: this.$n(this.campaignItemAvailability.available)
            };
            switch (this.product.rateType) {
                case "CPM":
                    return this.$t('component_productCard_availabilityCheckResultCPM', availableParams);
                case "CPC":
                    return this.$t('component_productCard_availabilityCheckResultCPC', availableParams);
            }
            return null;
        },
        targetTabInput: {
            get() {
                return {
                    targetURL: this.targetURL,
                    targetProduct: this.targetProduct,
                    targetLandingPage: this.targetLandingPage,
                };
            },
            set(value) {
                this.targetURL = value.targetURL;
                this.targetProduct = value.targetProduct;
                this.targetLandingPage = value.targetLandingPage;

                if (
                    this.targetProduct // there is TargetProduct
                    && this.targetProduct.rootTargetProductCategories.length > 0 // target has target category
                    && this.variableValueIdes.length == 0 // variable not selected yet
                    && Array.isArray(this.selectableVariableValues)
                    && this.selectableVariableValues.length > 0 // product has variables
                ) { // there is product and no variable value selected
                    // prefill  variable value
                    const targetProductCategory = this.targetProduct.rootTargetProductCategories[0];
                    let variableValues = this.selectableVariableValues.filter(function(item) {
                        return item.value == targetProductCategory.variableValueValue;
                    });
                    if(variableValues.length > 0) {
                        this.variableValueIdes = [variableValues[0].id];
                    }
                }
            },
        },
        filled() {
            return this.startDate.length > 0 && (parseISO(this.startDate))
                && this.endDate.length > 0 && (parseISO(this.endDate))
                && (
                    this.selectableVariableValues === null
                    || this.selectableVariableValues.length == 0
                    || (this.selectableVariableValues.length > 0 && this.variableValueIdes.length > 0)
                )
                && (
                    Number(this.product.price) <= this.customProductPrice
                )
                && !this.urlError;
        },
        totalPrice() {
            switch (this.product.rateType) {
                case 'Flat Rate':
                    if (this.startDate && this.endDate) {
                        return Number((differenceInDays(parseISO(this.endDate), parseISO(this.startDate)) + 1) / 7 * this.customProductPrice);
                    }
                    return parseFloat(this.product.price);
                case 'CPM':
                    return (this.orderedAmount * this.customProductPrice) / 1000;
                default:
                    return this.orderedAmount * this.customProductPrice;
            }
        },
        keywordsNotFilled() {
            return this.product.nativeType === ProductNativeType.TopInSearch
                && this.targetKeywords.filter(keyword => keyword.value.length > 0).length === 0;
        },
        unitPlaceHolder() {
            if (this.product.minAmount) {
                return (this.product.minAmount / Number(this.product.price)).toString();
            }

            return '0';
        },
    },
    watch: {
        startDate(newVal, oldVal) {
            this.emitData();
            if (
                this.isFlatRate && newVal
            ) {
                const endDate = add(parseISO(newVal), {days: 6});
                this.endDate = format(endDate, "yyyy-MM-dd")
            }
            // reset endDate if current end date before startDate
            if (
                this.startDate.length > 0
                && this.endDate.length > 0
                && isBefore(parseISO(this.endDate), parseISO(this.startDate))
            ) {
                this.endDate = "";
            }
            if (newVal !== oldVal) {
                this.resetErrorMessages();
            }
            // reset campaignItemAvailability for previous date
            this.campaignItemAvailability = null;
        },
        endDate() {
            this.emitData();
            this.campaignItemAvailability = null;
        },
        note() {
            this.emitData();
        },
        orderedAmount() {
            this.emitData();
        },
        variableValueIdes(newVal, oldVal) {
            this.emitData();
            if (this.hasAvailabilities) {
                if (
                    !newVal // empty scalar
                    || Array.isArray(newVal) && newVal.length == 0 // or empty array
                ) {
                    this.productAvailabilities = null;
                } else if (
                    oldVal != newVal
                    || Array.isArray(newVal) // akvais reload for multiple variable values
                ) {
                    this.loadAvailabilities();
                    this.resetErrorMessages();
                }
            }
            // reset campaignItemAvailability for previous date
            this.campaignItemAvailability = null;
        },
        customProductPrice() {
            this.emitData();
        },
        targetURL() {
            this.emitData();
        },
        targetProduct() {
            this.emitData();
        },
        targetKeywords() {
            this.emitData();
        },
        product() {
            this.customProductPrice = Number(this.product.price);
        },
        activityData: {
            handler(newVal) {
                if (newVal) {
                    this.initActivityData(newVal);
                }
            },
            deep: true,
        },
    },
    methods: {
        initActivityData(activityData) {
            this.startDate = activityData.startDate;
            this.endDate = activityData.endDate;
            this.note = activityData.note;
            this.orderedAmount = activityData.orderedAmount;
            this.variableValueIdes = activityData.variableValueIdes;
            this.customProductPrice = activityData.customProductPrice;
            this.targetURL = activityData.targetURL;
            this.targetProduct = activityData.targetProduct;
            this.targetLandingPage = activityData.targetLandingPage;
            this.targetKeywords = activityData.targetKeywords;
        },
        onOrderedAmountChanged(orderedAmount) {
            if ( typeof orderedAmount != 'string') { // handle only Talwind change event
                return;
            }

            const parseOrderedAmount = parseInt(orderedAmount);
            if (parseOrderedAmount > this.orderAmountLimit) {
                this.errorMessage = this.$t('component_addToolEditForm_error_ExceedOrderLimit', { n: this.$n(this.orderAmountLimit) });
            } else {
                this.resetErrorMessages();
            }

            if (this.product.minAmount && parseOrderedAmount !== 0 && orderedAmount.trim() !== '') {
                this.orderedAmount = parseOrderedAmount;
            }
        },
        async checkCampaignItemAvailability() {
            this.checkingCampaignItemAvailability = true;
            this.campaignItemAvailabilityError = null;
            let payload = {
                productId: this.product.id,
                startDate: this.startDate,
                endDate: this.endDate,
                orderedAmount: this.orderedAmount,
                variableValueIdes: this.variableValueIdes,
            };
            try {
                this.campaignItemAvailability = await this.$store.dispatch("campaigns/checkCampaignItemAvailability", payload);
            } catch (e) {
                this.campaignItemAvailabilityError = e.response.data.message;
            } finally {
                this.checkingCampaignItemAvailability = false;
            }
        },
        resetErrorMessages() {
            this.errorMessage = '';
            this.formErrors = {};
        },
        prefill(prefill) {
            console.log(prefill)
            this.startDate = format(prefill.startDate, 'yyyy-MM-dd');
            this.endDate = format(prefill.endDate, 'yyyy-MM-dd');
            const _this = this;
            this.$nextTick(() => {
                if(! _this.selectableVariableValues) {
                    return;
                }

                let selected = [];
                if (_this.hasMultipleVariableValues) {
                    _this.variableValuesIdesSelectModel = [prefill.variableValue];
                    selected = [..._this.variableValuesIdesSelectModel];
                } else {
                    _this.variableValuesIdesSelectModel = prefill.variableValue;
                    selected = [_this.variableValuesIdesSelectModel];
                }

                const normalizeOptions = _this.$refs.variableValuesSelect.normalizeOptions(
                    _this.selectableVariableValues.filter(item => selected.includes(item.id))
                );

                if(_this.hasMultipleVariableValues) {
                    _this.$refs.variableValuesSelect.selectedOptions = normalizeOptions;
                } else {
                    _this.$refs.variableValuesSelect.selectedOption = normalizeOptions[0];
                }
            });
        },
        emitData: debounce(function()  {
            this.$emit('data', {
                activityData: {
                    startDate: this.startDate,
                    endDate: this.endDate,
                    note: this.note,
                    orderedAmount: this.orderedAmount,
                    variableValueIdes: this.variableValueIdes,
                    customProductPrice: this.customProductPrice,
                    targetURL: this.targetURL,
                    targetProduct: this.targetProduct,
                    targetLandingPage: this.targetLandingPage,
                    targetKeywords: this.targetKeywords
                },
                valid: this.filled,
                totalPrice: this.totalPrice,
            });
        }, 200),
        setUrlError(error) {
            this.urlError = error;
        },
        async loadAvailabilities() {
            if(this.product.multipleVariableValues) {
                console.error("Check availabilities for multiple variable values is not supported.", [this.product, this.variableValueIdes]);
                this.productAvailabilities = [];
                return;
            }
            let payload = {
                productId: this.product.id,
                variableValueId: this.variableValueIdes[0] || null,
            };
            this.productAvailabilities = await this.$store.dispatch("products/getProductVariableValueAvailabilities", payload);

        },
        validate() {
            if (this.product.nativeType === ProductNativeType.TopInSearch && this.keywordsNotFilled) {
                return false;
            }
            return true;
        },
        parseLocaleNumber(inputValue) {
            let outputValue = (typeof inputValue === 'string') ? inputValue.replace(/\s/g, '').replace(',', '.') : inputValue;

            const parsedResult = parseFloat(outputValue);
            if (isNaN(parsedResult)) {
                return 0;
            }
            return parsedResult;
        }
    },
    created() {
        this.customProductPrice = Number(this.product.price);
        if(this.activityData) {
            this.initActivityData(this.activityData);
        }
        const prefill = this.$store.getters['products/prefill'];
        if (prefill) {
            this.prefill(prefill);
        }
        this.$store.dispatch('products/setPrefill', null);
    }
}
</script>

<style scoped>

</style>
