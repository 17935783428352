<template>
    <div class="max-w-screen-2xl pt-1 pb-8 px-4 md:px-8 mx-auto">
        <simple-breadcrumb v-if="!loadingCampaign && campaignExists && campaignItem" :items="breadcrumbs" />

        <not-found-slate v-if="!loadingCampaign && !campaignExists" />

        <loading-box v-model="loadingCampaign" min-height="12rem" theme="light">
            <div v-if="!loadingCampaign && campaignExists">
                <header class="flex flex-wrap items-start justify-between mt-5 mb-3 space-y-2 lg:space-y-0">
                    <div class="flex items-center mb-4 space-x-2 pr-3">
                        <h1 class="text-purple-100 h1">{{ product.titleOrName }}</h1>
                    </div>
                </header>

                <div class="flex items-start justify-start">
                    <div>
                        <p class="text-base">{{ product.description }}</p>
                        <a :href="product.moreInfoUrl" target="_blank" class="text-blue-400 underline">{{ product.moreInfoUrlTitle }}</a>
                    </div>
                    <img v-if="imgSrc" :src="imgSrc" class="h-40 rounded-2xl ml-auto">
                </div>

                <div class="rounded-2xl bg-white p-4 mt-2">
                    <ad-tool-edit-form
                        :product="product"
                        :company-restrictions="companyRestrictions"
                        :activity-data="activityData"
                        :allow-custom-price="isProductAuction"
                        @data="storeData"
                    ></ad-tool-edit-form>

                    <info-message type="error" class="mt-2" v-if="errorMessage">
                        {{ errorMessage }}
                    </info-message>

                    <div class="flex items-center justify-between mt-6">
                        <div class="text-base">
                            {{ $t('component_productCard_totalPrice') }}
                            <strong>{{ $n(totalPrice, "currency") }}</strong>
                            <span v-if="!isFlatRate && isMinAmountSet">
                            ({{ $t('component_productCard_customPrice_min') }}&nbsp;{{ $n(product.minAmount, 'currency') }})
                        </span>
                        </div>

                        <div class="flex flex-wrap items-center">
                            <text-button class="text-gray-400"
                                         :to="{name: 'campaignDetail', params: {campaignId: campaign.id}}">
                                {{ $t('common_back_to_campaign') }}
                            </text-button>

                            <t-button
                                v-if="campaignItem"
                                :disabled="!isSendEnabled"
                                :variant="isSendEnabled ? 'bigGreen': 'bigGray'"
                                class="ml-4 sm:my-0 my-3 flex items-center"
                                @click="updateCampaignItem"
                            >
                                <loading-icon v-if="isWorking"></loading-icon>
                                <div v-if="isSendEnabled" class="flex bg-green-200 rounded-full p-1 mr-2 text-black"
                                     :class="{'grayscale': !valid}">
                                    <c-icon class="w-3 h-3" icon="check"></c-icon>
                                </div>

                                {{ $t('common_save') }}
                            </t-button>
                        </div>
                    </div>
                </div>
            </div>
        </loading-box>
    </div>
</template>
<script>

import {mapGetters} from "vuex";
import ImageService from "@/services/ImageService";
import LoadingIcon from "@/components/LoadingIcon";
import SimpleBreadcrumb from "@/components/SimpleBreadcrumb";
import CIcon from "@/components/base/icons/CIcon";
import TextButton from "@/components/base/buttons/TextButton.vue";
import AdToolEditForm from "@/components/AdToolEditForm.vue";
import InfoMessage from "@/components/base/InfoMessage.vue";
import NotFoundSlate from "@/components/NotFoundSlate.vue";
import LoadingBox from "@/components/LoadingBox.vue";

export default {
    name: "EditActivityView",
    components: {
        LoadingBox,
        NotFoundSlate,
        InfoMessage,
        AdToolEditForm,
        TextButton,
        SimpleBreadcrumb,
        LoadingIcon,
        CIcon
    },
    props: {
    },
    data() {
        return {
            loadingCampaign: false,
            campaignExists: null,

            isWorking: false,

            activityData: {},
            totalPrice: 0,
            valid: false,

            errorMessage: '',
            urlError: false,
        };
    },
    metaTags() {
        return {
            title: this.$t('view_campaignItemDetail_metaTitle') + ' - ' + this.$t('common_meta_title'),
        }
    },
    computed: {
        ...mapGetters("auth", ["isRetailer", 'isVendor', 'isAgency']),
        breadcrumbs() {
            const that = this;

            const breadcrumbs = [
                {
                    title: that.$t('common_campaignsManagement'),
                    current: false,
                    to: {name: "campaignList"}
                },
            ];

            if (that.campaignItem !== null) {
                breadcrumbs.push({
                    title: this.campaignBreadcrumbName,
                    current: false,
                    onClick: () => {
                        that.closeDetail();
                    },
                });
                breadcrumbs.push({
                    title: that.campaignItem.generatedName,
                    current: 'page',
                });
            }

            return breadcrumbs;
        },
        campaign() {
            return this.$store.getters['campaigns/currentCampaign'];
        },
        campaignBreadcrumbName() {
            let name = this.campaign.name;

            if ((this.isRetailer || this.isAgency) && this.campaign && this.campaign.company) {
                name = name + ' (' + this.campaign.company.name + ')';
            }

            return name;
        },
        campaignItem() {
            if (!this.campaign) {
                return null;
            }
            const campaignItemId = this.$route.params.campaignItemId;
            return this.campaign.campaignItems.find((campaignItem) => campaignItem.id.toString() === campaignItemId.toString());
        },
        product() {
            if(!this.campaignItem) {
                return null;
            }

            return this.campaignItemData.product
        },
        customProductPriceFromInput: {
            get() {
                return  this.customProductPrice === null ? this.defaultProductPrice : this.customProductPrice;
            },
            set(value) {
                if(parseInt(value) < parseInt(this.defaultProductPrice)) {
                    value = this.defaultProductPrice;
                }
                this.customProductPrice = value;
            },
        },
        isFlatRate() {
            return this.product.rateType === 'Flat Rate';
        },
        localeLanguage() {
            return this.locale !== 'cs-CZ' ? this.locale.substr(0, 2) :  'cs';
        },
        sasLineItemURL() {
            if (this.campaignItem && this.campaignItem.sasInfo) {
                return this.campaignItem.sasInfo.lineItemURL;
            }
            return null;
        },
        sasFlightURL() {
            if (this.campaignItem && this.campaignItem.sasInfo) {
                return this.campaignItem.sasInfo.flightURL;
            }
            return null;
        },
        sasCreativeURL() {
            if (this.campaignItem && this.campaignItem.sasInfo) {
                return this.campaignItem.sasInfo.creativeURL;
            }
            return null;
        },
        imgSrc() {
            if (!this.product || !this.product.image) {
                return null;
            }
            return ImageService.imageURL(this.product.image.id);
        },
        companyRestrictions() {
            return {
                advertiserCompanyId: this.campaign?.company?.id || null,
                advertiserCompanyContactId: this.campaign?.companyContact?.id || null,
            }
        },
        isSendEnabled() {
            if (!this.isRetailer && !this.isFlatRate) {
                return (
                    !this.isWorking
                    && this.valid
                    && this.totalPrice > this.product.minAmount
                );
            }

            return !this.isWorking && this.valid;
        },
        isProductAuction() {
            if (this.isRetailer) {
                return true;
            }

            return this.product?.auction ?? false;
        },
        isMinAmountSet() {
            return this.product.minAmount > 0;
        },
    },
    watch: {
        campaignItem: {
            handler(value) {
                if (value) {
                    this.fillFromCampaignItem(value);
                }
            },
            deep: true
        },
    },
    methods: {
        closeDetail() {
            this.$router.push({name: 'campaignDetail', params: {id: this.$route.params.campaignId}});
        },
        async getCampaignItemData() {
            return await this.$store.dispatch("campaigns/getCampaignItem", {campaignItemId: this.campaignItem.id});
        },
        async loadData() {
            const campaignId = this.$route.params.campaignId;
            try {
                this.loadingCampaign = true;
                await this.$store.dispatch('campaigns/setCurrentCampaign', {campaignId: campaignId});
                this.$metaTags({title: this.$t('view_campaignItemDetail_metaTitle') + ' „' + this.campaignItem.generatedName + '“ - ' + this.$t('common_meta_title'),});
                this.campaignItemData = await this.getCampaignItemData();
                this.campaignExists = true;
            } catch {
                this.campaignExists = false;
            } finally {
                this.loadingCampaign = false;
            }
        },
        storeData(data) {
            this.activityData = data.activityData;
            this.totalPrice = data.totalPrice;
            this.valid = data.valid;
        },
        async updateCampaignItem() {
            this.isWorking = true;
            try {
                await this.$store.dispatch("campaigns/updateCampaignItem", {
                    campaignItem: this.campaignItem,
                    startDate: this.activityData.startDate,
                    endDate: this.activityData.endDate,
                    note: this.activityData.note,
                    orderedAmount: this.activityData.orderedAmount,
                    variableValueIdes: this.activityData.variableValueIdes,
                    customProductPrice: this.activityData.customProductPrice,
                    targetURL: this.activityData.targetURL,
                    targetProductId: this.activityData.targetProduct ? this.activityData.targetProduct.id : null,
                    targetLandingPageId: this.activityData.targetLandingPage ? this.activityData.targetLandingPage.id : null,
                    targetKeywords: this.activityData.targetKeywords
                });
            } catch (e) {
                this.errorMessage = e;
                if (e.response && e.response.data.message) {
                    this.errorMessage = e.response.data.message;
                }
                if (e.response.data.formErrors) { // there are some form errors
                    // DOTO: throw errors
                    this.errorMessage = Object.values(e.response.data.formErrors).join(' ');
                    this.formErrors = e.response.data.formErrors;
                }
            } finally {
                this.isWorking = false;
            }
        },
        resetErrorMessages() {
            this.errorMessage = '';
            this.formErrors = {};
        },
        setUrlError(error) {
            this.urlError = error;
        },
        fillFromCampaignItem(campaignItem) {
            this.activityData = {
                startDate: campaignItem.startDate,
                endDate: campaignItem.endDate,
                note: campaignItem.note,
                targetURL: campaignItem.targetURL,
                customProductPrice: campaignItem.unitPrice,
                variableValueIdes: campaignItem.variableValues.map((value) => value.id),
                targetKeywords: campaignItem.targetKeywords,
                targetProduct: campaignItem.targetProduct,
                targetLandingPage: campaignItem.targetLandingPage,
                orderedAmount: campaignItem.orderedAmount
            };
        }
    },
    // async created() {
    //     if (this.campaignItem) {
    //         this.fillFromCampaignItem(this.campaignItem);
    //     }
    //     if (this.campaignItem && this.hasAvailabilities) {
    //         await this.loadAvailabilities();
    //     }
    // },
    async beforeRouteEnter(to, from, next) {
        await next(async (vm) => {
            await vm.loadData();
        });
    },
    async beforeRouteUpdate(to, from, next) {
        await next(async (vm) => {
            await vm.loadData();
        });
    }
}
</script>

<style scoped>

</style>
