<template>
    <t-modal v-model="showDialog" variant="wide">

        <div class="md:p-20 p-8">
            <div class="text-center">
                <h1 class="text-blue-400 h1 mb-5 pr-5">
                    {{ $t('component_productSelectDialog_header')}}
                </h1>
                <p>
                   {{ $t('component_productSelectDialog_description') }}
                </p>
                <loading-box theme="light" v-model="loading" min-height="12rem">
                    <div v-for="category in categories" :key="category.id">
                        <h3 class="h2 text-black font-bold mt-14 mb-6">{{ category.title }}</h3>

                        <show-more-text
                            v-if="category.description.trim() !== '' || category.shortDescription.trim() !== ''"
                            :perex="category.shortDescription"
                            :text="category.description"
                            :more-text="$t('common_showMore')"
                            :less-text="$t('common_showLess')">
                        </show-more-text>

                        <div class="flex flex-wrap text-left">
                            <div class="xs:w-1/2 xl:w-1/3 w-full p-1"
                                 v-for="product in category.products.filter(p => p.active)"
                                 :key="product.id"
                                 @click="selectProduct(product.id)"
                            >
                                <t-card variant="product" class="h-60">
                                    <div class="flex">
                                        <div>
                                            <c-icon v-if="product.icon" class="inline-block w-6 h-6 mr-1 mt-1" :icon="product.icon"></c-icon>
                                        </div>
                                        <h2 class="text-xl font-medium mb-1 ml-2.5 leading-[1.1] text-blue-400 min-h-[44px]">
                                            {{ product.titleOrName | truncate(44, "...") }}
                                        </h2>
                                    </div>
                                    <p class="leading-5 h-16">
                                        {{ product.description | truncate(100, "...") }}
                                    </p>
                                    <p class="font-medium text-base mt-4">
                                        {{ getRateTypeName(product.rateType) }}:
                                        <span class="text-blue-400">{{ $n(product.price, 'currency') }}</span>
                                    </p>
                                </t-card>
                            </div>
                        </div>
                    </div>
                </loading-box>
            </div>
        </div>

        <template v-slot:button>
            <div class="w-8 h-8 bg-blue-100 rounded-full flex">
                <svg-icon name="x" class="w-3 h-3 m-auto"></svg-icon>
            </div>
        </template>
    </t-modal>

</template>
<script>

import stringFilters from "@/mixins/string-filters";
import SvgIcon from '../components/SvgIcon.vue';
import LoadingBox from "@/components/LoadingBox";
import ShowMoreText from "@/components/ShowMoreText";
import {getRateTypeName} from "@/utils/RateType";
import CIcon from "@/components/base/icons/CIcon.vue";

export default {
    name: "ProductSelectDialog",
    components: {
        LoadingBox,
        SvgIcon,
        ShowMoreText,
        CIcon,
    },
    mixins: [stringFilters],
    props: {
        value: {
            default: false,
        },
        campaign: {
            type: Object,
            required: true
        }
    },
    data: () => {
        return {
            loading: false,
            categories: [],
            productId: null,
        };
    },
    computed: {
        showDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    watch: {
        async showDialog(newValue) {
            if (!newValue) {
                return;
            }
            this.loading = true;
            this.categories = await this.$store.dispatch("products/getUserCategoriesWithProducts");
            this.loading = false;
        }
    },
    methods: {
        getRateTypeName(rateType) {
            return getRateTypeName(rateType);
        },
        addedProduct() {
            this.showDialog = false;
            this.$emit('selected');
        },
        selectProduct(id) {
            this.$router.push({
                name: 'addAdDoolDetail',
                params: {campaignId: this.campaign.id, selectedProductId: id}
            });
        }
    },
    filters: {},
};
</script>
