<template>
    <base-button :to="to" class="p-2">
            <t-card variant="product" class="h-60 hover:!bg-gray-50 !cursor-auto">
                <div class="flex align-center">
                    <c-icon v-if="product.icon" class="inline-block w-6 h-6 mr-1 mt-1 w-[20%]" :icon="product.icon"></c-icon>
                    <h2 class="text-xl font-medium mb-1 leading-[1.1] text-blue-400">
                        {{ product.titleOrName | truncate(50, "...") }}
                    </h2>
                </div>
                <p v-if="product.description" class="leading-6 h-16">
                    {{ product.description | truncate(72, "...") }}
                </p>
                <p class="mb-1 mt-2 font-medium text-base">
                    {{ rateTypeName }}:
                    <span class="text-blue-400">{{ $n(product.price, "currency") }}</span>
                </p>
                <div class="flex justify-between">
                    <t-button
                        v-if="hasAvailabilities"
                        @click.stop.prevent="showAvailabilities = true"
                        class="flex items-center relative group pl-2.5 mb-2"
                        variant="smallBlue"
                    >
                        <c-icon class="mr-1.5 w-3 h-3 -mt-0.5" icon="calendar-31"></c-icon>
                        {{ $t("common_availability")}}
                    </t-button>
                    <div v-else>&nbsp;</div>
                    <t-button
                        @click="$emit('click')"
                        class="flex items-center relative group pr-2.5 mb-2"
                        variant="smallGreen"
                    >
                        {{ $t("common_select") }}
                        <c-icon class="ml-1.5 w-3 h-3" icon="arrow-right"></c-icon>
                    </t-button>
                </div>

            </t-card>
        <portal to="main-overlay">
            <product-availabilities-card
                @click.stop.prevent
                v-if="hasAvailabilities"
                :showAvailabilities="showAvailabilities"
                @close="showAvailabilities = false"
                :product="product"
            />
        </portal>
    </base-button>
</template>
<script>
import stringFilters from "@/mixins/string-filters";
import ProductAvailabilitiesCard from "@/components/ProductAvailabilitiesCard";
import BaseButton from "@/components/base/BaseButton.vue";
import CIcon from "@/components/base/icons/CIcon.vue";
import {getRateTypeName} from "@/utils/RateType";

export default {
    name: "ProductListItem",
    components: {CIcon, BaseButton, ProductAvailabilitiesCard},
    mixins: [stringFilters],
    props: ["product", 'to'],
    data() {
        return {
            showAvailabilities: false
        };
    },
    computed: {
        hasAvailabilities() {
            return this.product.rateType === "Flat Rate" && this.product.checkAvails;
        },
        rateTypeName() {
            return getRateTypeName(this.product.rateType);
        }
    },
    methods: {},
};
</script>
