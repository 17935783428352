<template>
    <div v-if="product" class="max-w-screen-2xl pt-1 pb-8 px-4 md:px-8 mx-auto">
        <simple-breadcrumb :items="breadcrumbs"></simple-breadcrumb>

        <header class="flex flex-wrap items-start justify-between mt-5 mb-3 space-y-2 lg:space-y-0">
            <div class="flex items-center mb-4 space-x-2 pr-3">
                <h1 class="text-purple-100 h1">{{ product.titleOrName }}</h1>
            </div>
        </header>

        <div class="flex items-start justify-start">
            <div>
                <p class="text-base">{{ product.description }}</p>
                <a :href="product.moreInfoUrl" target="_blank" class="text-blue-400 underline">{{ product.moreInfoUrlTitle }}</a>
                <div class="flex items-center my-8 space-x-2">
                    <svg-icon name="info" class="mr-2 "/>
                    <span class="text-gray-500">{{ priceForText }}</span>
                    <span class="text-gray-700 font-medium">{{ $n(product.price, "currency") }}</span>
                </div>
            </div>
            <img v-if="imgSrc" :src="imgSrc" class="h-40 rounded-2xl ml-auto">
        </div>

        <h2 class="h2 mb-2">
            {{$t('view_AdToolDetail_addToolToCampaignHeading')}}
            <span v-if="campaign">{{ campaign.name }}</span>
        </h2>

        <t-card>

            <div v-if="!isAddToSelectedCampaign">
                <h2 v-if="isRetailerOrAgency" class="h3 text-purple-100 mb-4">{{$t('view_AdToolDetail_vendor')}}</h2>
                <div v-if="isRetailerOrAgency"
                    class="grid grid-cols-2 gap-4"
                    >
                    <div class="col-span-1">
                        <label for="" class="text-gray-600 mb-3 block">
                            {{ $t('common_advertiserCompany') }}
                        </label>
                        <t-rich-select
                            v-model="companyId"
                            :options="companies"
                            value-attribute="id"
                            text-attribute="name"
                            required
                        >
                        </t-rich-select>
                    </div>

                    <div class="col-span-1">
                        <label for="" class="text-gray-600 mb-3 block">
                            {{ $t('common_advertiserCompanyContact') }}
                        </label>
                        <t-rich-select
                            v-model="companyContactId"
                            :options="users"
                            value-attribute="id"
                            text-attribute="name"
                            :disabled="!companyId"
                            clearable
                        >
                        </t-rich-select>
                    </div>
                </div>
            </div>

            <div v-if="showForm">
                <div v-if="!isAddToSelectedCampaign">
                    <h2 class="h3 text-purple-100 mb-4 mt-5">{{$t('view_AdToolDetail_campaign')}}</h2>

                    <div class="mx-6 my-4">
                        <horizontal-tabs v-model="campaignSelection">
                            <horizontal-tab-content identifier="new" :tab-name="$t('common_newCampaign')">
                                <div class="px-8 w-full">
                                    <label for="" class="text-gray-600 mb-3 block">
                                        {{ $t('common_name') }}
                                    </label>
                                    <t-input class="w-full" v-model="campaignName" required></t-input>
                                </div>
                            </horizontal-tab-content>
                            <horizontal-tab-content identifier="existing" :tab-name="$t('views_adToolDetail_selectCampaign')">
                                <t-rich-select
                                    ref="campaignSelect"
                                    v-model="campaignId"
                                    :fetch-options="fetchCampaigns"
                                    value-attribute="id"
                                    text-attribute="name"
                                    clearable
                                >
                                    <template  v-slot:option="{ option }">
                                        <div class="grid grid-cols-3 text-left py-4 cursor-pointer px-1">
                                            <p>
                                                {{$t('component_selectCampaignDialog_campaignName', {name: option.raw.name}) }}
                                            </p>
                                            <p>{{ $tc('component_selectCampaignDialog_items', option.raw.campaignItemsCount) }}</p>
                                            <p class="text-right">
                                                {{$t('common_price')}} <span class="font-bold">{{ $n(option.raw.totalPrice, "currency") }}</span>
                                            </p>
                                        </div>
                                    </template>
                                </t-rich-select>
                            </horizontal-tab-content>
                        </horizontal-tabs>
                    </div>
                </div>

                <h2 class="h3 text-purple-100 mb-4 mt-5">{{$t('view_AdToolDetail_tool')}}</h2>

                <ad-tool-edit-form
                        ref="form"
                        :product="product"
                        :company-restrictions="companyRestrictions"
                        :allow-custom-price="isProductAuction"
                        :allow-custom-price-text="isProductAuctionText"
                        @data="storeData"
                        :orderAmountLimit="orderAmountLimit"
                ></ad-tool-edit-form>

                <info-message type="error" class="mt-2" v-if="errorMessage">
                    {{ errorMessage }}
                </info-message>

                <div class="flex items-center justify-between mt-6">
                    <div class="text-base">
                        {{ $t('component_productCard_totalPrice') }}
                        <strong>{{ $n(totalPrice, "currency") }}</strong>
                        <span v-if="!isFlatRate && isMinAmountSet">
                            ({{ $t('component_productCard_customPrice_min') }}&nbsp;{{ $n(product.minAmount, 'currency') }})
                        </span>
                    </div>

                    <div class="flex flex-col items-end space-y-2">
                        <text-button v-if="isAddToSelectedCampaign" class="text-gray-400" :to="{name: 'campaignDetail', params: {campaignId: campaignId}}">
                            {{ $t('common_back_to_campaign') }}
                        </text-button>
                        <c-button
                            color="green"
                            size="big"
                            prepend-icon="plus"
                            :disabled="!enableSend"
                            :loading="isSending"
                            @click="processForm"
                        >
                            {{ $t('component_productCard_buttonAddToCampaign')}}
                        </c-button>
                        <p v-if="!enableSend" class="pb-1 text-gray-600 text-xs">
                            {{$t('view_AdToolDetail_pleaseCheckData')}}
                        </p>
                    </div>
                </div>

            </div>

        </t-card>
    </div>
</template>

<script>
import AdToolEditForm from "@/components/AdToolEditForm.vue";
import {mapGetters} from "vuex";
import SvgIcon from "@/components/SvgIcon.vue";
import ImageService from "@/services/ImageService";
import HorizontalTabs from "@/components/HorizontalTabs.vue";
import HorizontalTabContent from "@/components/HorizontalTabContent.vue";
import SimpleBreadcrumb from "@/components/SimpleBreadcrumb.vue";
import {CampaignStateIDEnum} from "@/utils/CampaignStateEnum";
import TextButton from "@/components/base/buttons/TextButton.vue";
import CButton from "@/components/base/buttons/CButton.vue";
import InfoMessage from "@/components/base/InfoMessage.vue";
export default {
    name: "AdToolDetailView",
    components: {
        CButton,
        InfoMessage,
        TextButton,
        SimpleBreadcrumb,
        HorizontalTabContent,
        HorizontalTabs,
        SvgIcon,
        AdToolEditForm
    },
    data() {
        return {
            product: null,
            companyId: null,
            companyContactId: null,
            companies: [],
            users: [],

            activityData: null,
            totalPrice: 0,
            orderedAmount: 0,
            orderAmountLimit: 2000000000,

            campaignSelection: 'new',
            campaignName: '',
            campaignId: null,
            currentCamapaign: null,

            valid: false,
            errorMessage: '',

            isSending: false,

            isAddToSelectedCampaign: false, // if true we are adding product to pre-selected campaign
        }
    },
    metaTags() {
        return {
            title: this.$t('common_products') + ' - ' + this.$t('common_meta_title'),
        }
    },
    computed: {
        ...mapGetters('auth', ['isRetailer', 'isAgency']),
        breadcrumbs() {
            if (this.campaign) {
                return [
                    {
                        title: this.$t('common_campaigns'),
                        current: false,
                        to: {name: 'campaignList'}
                    },
                    {
                        title: this.campaign.name,
                        current: false,
                        to: {name: 'campaignDetail', params: {campaignId: this.campaign.id}}
                    },
                    {
                        title: this.product.name,
                        current: 'page',
                    },
                ];
            } else {
                return [
                    {
                        title: this.$t('common_products'),
                        current: false,
                        to: {name: 'productList'}
                    },
                    {
                        title: this.product.name,
                        current: 'page',
                    },
                ];
            }
        },
        companyRestrictions() {
            return {
                advertiserCompanyId: this.companyId,
                advertiserCompanyContactId: this.companyContactId,
            }
        },
        isRetailerOrAgency() {
            return this.isRetailer || this.isAgency;
        },
        showForm() {
            return this.isAddToSelectedCampaign || !(this.isRetailerOrAgency && !this.companyId);
        },
        isFlatRate() {
            return this.product.rateType === 'Flat Rate';
        },
        priceForText() {
            switch (this.product.rateType) {
                case "Flat Rate":
                    return this.$t('component_productCard_priceTextFlatRate')
                case "CPM":
                    return this.$t('component_productCard_priceTextCMP');
                case "CPC":
                    return this.$t('component_productCard_priceTextCPC');
                default:
                    return this.product.rateType;
            }
        },
        imgSrc() {
            if (!this.product || !this.product.image) {
                return null;
            }
            return ImageService.imageURL(this.product.image.id);
        },
        isAboveBudget() {
            return this.totalPrice >= this.product.minAmount;
        },
        isOrderAmountExceedsLimit() {
            return this.orderedAmount > this.orderAmountLimit;
        },
        enableSend() {
            return this.valid
                && (
                    (this.campaignSelection === 'new' && this.campaignName)
                    || (this.campaignSelection === 'existing' && this.campaignId)
                ) 
                && this.isAboveBudget
                && !this.isOrderAmountExceedsLimit;
        },
        campaign: {
            get() {
                return this.$store.getters["campaigns/currentCampaign"];
            },
            set(value){ 
                this.currentCamapaign = value;
            },
        },
        isProductAuction() {
            if (this.isRetailer) {
                return true;
            }

            return this.product?.auction ?? false;
        },
        isProductAuctionText() {
            return this.product?.auction ?? false;
        },
        isMinAmountSet() {
            return this.product.minAmount > 0;
        },
    },
    watch: {
        async companyId(value, oldValue) {
            if (value) {
                this.users = await this.$store.dispatch('users/getCompanyContacts', {companyId: value});
                this.companyContactId = null;
            } else {
                this.users = [];
                this.companyContactId = null;
            }
            if(oldValue) {
                this.campaignId = null;
                this.$refs.campaignSelect.filterOptions('');
            }
        },
    },
    methods: {
        async getProduct(id) {
            this.product = await this.$store.dispatch('products/getProduct', {productId: id});
        },
        storeData(data) {
            this.activityData = data.activityData;
            this.totalPrice = data.totalPrice;
            this.orderedAmount = data.activityData.orderedAmount;
            this.valid = data.valid;
        },
        async fetchCampaigns(query) {
            const companyContacts = [];
            if(this.companyContactId) {
                companyContacts.push(this.companyContactId);
            }
            const companies = [];
            if(this.companyId) {
                companies.push(this.companyId);
            }

            await this.$store.dispatch('campaigns/filterCampaigns', {
                search: query,
                states: [
                    CampaignStateIDEnum.InProcess,
                    CampaignStateIDEnum.UserCheck,
                ],
                companies,
                companyContacts,
            });
            return ({results: this.$store.getters['campaigns/listData'].campaigns});
        },
        async processForm() {
            this.isSending = true;
            if(!this.$refs.form.validate()) {
                this.isSending = false;
                return;
            }
            let campaignId = this.campaignId;
            if (this.campaignSelection === 'new') {
                const campaign = await this.createCampaign();
                campaignId = campaign.id;
            }
            await this.addToCampaign(campaignId);
            this.isSending = false;
        },
        async createCampaign() {
            const campaign = {
                name: this.campaignName,
            };
            if(this.$store.getters['auth/isVendor']) {
                const salesPersons = await this.getLoggedUserSalesPersons();
                if (salesPersons.length > 0) { // set first sales person as default
                    campaign.salesPersonId = salesPersons[0].id;
                }
            }

            if(this.$store.getters['auth/isAgency']) {
                campaign.companyId =  this.companyId;
                campaign.contactId = this.companyContactId;
                campaign.agencyId = this.$store.getters['auth/agencyId'];
                campaign.agencyContactId = this.$store.getters['auth/agencyContactId'];
            }

            return await this.$store.dispatch('campaigns/createCampaign', campaign);
        },
       async addToCampaign(campaignId) {
            this.isWorking = true;
            // prepare data to save
            const activityData = {
                campaignItem: this.activityData.campaignItem,
                startDate: this.activityData.startDate,
                endDate: this.activityData.endDate,
                note: this.activityData.note,
                orderedAmount: this.activityData.orderedAmount,
                customProductPrice: this.activityData.customProductPrice,
                variableValueIdes: this.activityData.variableValueIdes,
                targetURL: this.activityData.targetURL,
                targetProductId: this.activityData.targetProduct ? this.activityData.targetProduct.id : null,
                targetLandingPageId: this.activityData.targetLandingPage ? this.activityData.targetLandingPage.id : null,
                targetKeywords: this.activityData.targetKeywords
            };
            try {
                const campaignItem = await this.$store.dispatch("campaigns/addCampaignItem", {
                    id: campaignId,
                    productId: this.product.id,
                    ...activityData,
                });
                const campaign = await this.$store.dispatch("campaigns/getCampaign", {campaignId: campaignId});
                await this.$store.dispatch("addedProduct/showToast", {
                    product: this.product,
                    campaign: campaign,
                    campaignItem: campaignItem
                });
            } catch (e) {
                this.errorMessage = e;
                if (e.response && e.response.data.message) {
                    this.errorMessage = e.response.data.message;
                }
                if (e.response.data.formErrors) { // there are some form errors
                    // DOTO: throw errors
                    this.errorMessage = Object.values(e.response.data.formErrors).join(' ');
                }
            } finally {
                this.isWorking = false;
            }
        },
       async loadData(to) {
            if(to.params.campaignId) {
                this.campaignId = to.params.campaignId;
                this.campaign = await this.$store.dispatch(
                    'campaigns/setCurrentCampaign',
                    {campaignId: this.campaignId}
                );
                this.companyId = this.campaign.company.id;
                this.companyContactId = this.campaign.companyContact?.id || null;
                this.campaignSelection = 'existing';
                this.isAddToSelectedCampaign = true;
            } else {
                await this.$store.dispatch('campaigns/unsetCurrentCampaign');
            }
            await this.getProduct(to.params.selectedProductId);
            if (this.isRetailerOrAgency) {
                const userId = this.$store.getters['auth/userId'];
                this.companies = await this.$store.dispatch('users/getCompanies', {userId});
            }
            this.$metaTags({title: this.$t('common_products') + ' „' + this.product.name + '“ - ' + this.$t('common_meta_title'),})
        },
        async getLoggedUserSalesPersons() {
            const user = await this.$store.dispatch('auth/getLoggedUser');
            return user.salesPersons;
        },
    },
    beforeRouteEnter(to, from, next) {
        next(async (vm) => {
            await vm.loadData(to);
        });
    },
    async beforeRouteUpdate(to, from, next) {
        await this.loadData(to);
        next();
    },
}
</script>

<style scoped>

</style>
