<template>
    <div class="flex items-center flex-wrap">
        <div v-if="displayActualOspRatio && isOspRatioDefined" class="flex items-center my-1 mx-2">
            <span>
                {{ $n(ospRatio, { style: 'percent', minimumFractionDigits: 2, maximumSignificantDigits: 2 }) }}
                {{ $tc('common_ospRatio', actualImpressions) }}
            </span>
        </div>
        <div v-if="displayActualImpressions && isActualImpressionsDefined" class="flex items-center my-1 mx-2">
            <svg-icon name="square-rounded" class="mr-2 h-5"></svg-icon>
            <span>{{ $tc('common_pluralize_impressions', actualImpressions, { impressions: $n(actualImpressions) }) }}</span>
        </div>
        <div v-if="displayVisibilityRatio && isActualViewabilityDefined" class="flex items-center my-1 mx-2">
            <svg-icon name="ico-eye" class="mr-1"></svg-icon>
            <span>{{ $tc('common_pluralize_views', viewability, { views: $n(viewability / 100, { style: 'percent', minimumFractionDigits: 2, maximumSignificantDigits: 2 }) }) }}</span>
        </div>
        <div v-if="displayActualClicks && isActualClicksDefined" class="flex items-center my-1 mx-2">
            <svg-icon name="ico-click" class="mr-1"></svg-icon>
            <span>{{ $tc('common_pluralize_clicks', actualClicks, { clicks: $n(actualClicks) }) }}</span>
        </div>
        <div v-if="displayActualCtrRatio && isActualCtrRatioDefined" class="flex items-center my-1 mx-2">
            <svg-icon name="ico-ctr" class="mr-1"></svg-icon>
            <span>
                {{ $n(actualCtrRatio, { style: 'percent', minimumFractionDigits: 2, maximumSignificantDigits: 2 }) }}
                {{ $tc('common_ctrRatio', actualCtrRatio) }}
            </span>
        </div>
    </div>
</template>
<script>
import SvgIcon from "@/components/SvgIcon"
import {mapGetters} from "vuex";

export default {
    name: 'activity-actual-performance',
    components: {SvgIcon},
    props: {
        campaignItem: {
            type: Object,
            required: true
        },
    },
    computed: {
        ...mapGetters('appSettings', ['displayVisibilityRatio', 'displayActualClicks', 'displayActualImpressions', 'displayActualCtrRatio', 'displayActualOspRatio']),
        actualClicks() {
            return this.campaignItem.actualClicks
        },
        viewability() {
            return this.campaignItem.visibilityRatio
        },
        actualImpressions() {
            return this.campaignItem.actualImpressions
        },
        actualCtrRatio() {
            return this.campaignItem.actualClicks / this.campaignItem.actualImpressions;
        },
        ospRatio() {
            return this.campaignItem.ospRatio ?? null;
        },
        isActualClicksDefined() {
            return this.actualClicks !== null;
        },
        isActualViewabilityDefined() {
            return this.viewability !== null;
        },
        isActualImpressionsDefined() {
            return this.actualImpressions !== null;
        },
        isActualCtrRatioDefined() {
            return this.actualClicks !== null && this.actualImpressions !== null;
        },
        isOspRatioDefined() {
            return this.ospRatio !== null;
        },
    }
}
</script>
